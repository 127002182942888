import type { Dispatch, SetStateAction, ReactNode } from 'react';

import classNames from '../../../../../lib/classnames';

export interface TabsChildrenProps {
    isActive?: boolean;
    isWarning?: boolean;
    itemId: number;
    children: ReactNode;
    setActiveTab: Dispatch<SetStateAction<number>>;
}

export default function TabsChildren({
    isActive,
    isWarning,
    itemId,
    children,
    setActiveTab,
}: TabsChildrenProps): JSX.Element {
    function onClickHandler(): void {
        setActiveTab(itemId);
    }

    const colorClasses = {
        default:
            'text-gray-600 dark:text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300',
        defaultActive:
            'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500',
        warning: 'mx-2 border-transparent text-red-800 dark:text-red-500',
        warningActive: 'mx-2 text-red-800 border-red-800 dark:text-red-500 dark:border-red-500',
    };

    let colorClass = colorClasses.default;

    if (isActive) {
        colorClass = colorClasses.defaultActive;
    }

    if (isWarning) {
        colorClass = colorClasses.warning;
    }

    if (isWarning && isActive) {
        colorClass = colorClasses.warningActive;
    }

    return (
        <button
            type="button"
            onClick={onClickHandler}
            className={classNames('truncate block p-4 rounded-t-lg border-b-2', colorClass)}>
            {children}
        </button>
    );
}
