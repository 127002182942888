import { fill } from '@cloudinary/url-gen/actions/resize';
import Head from 'next/head';
import type { Dispatch, SetStateAction } from 'react';

import AvatarPartager from '../../../../components/basic/AvatarPartager';
import Button from '../../../../components/elements/Button';
import BasicLayout from '../../../../components/modules/Layout/BasicLayout';
import Seo from '../../../../components/modules/Seo';
import SubscriberForm from '../../../../components/modules/SubscriberForm';
import type { SubscriberInputPublication } from '../../../../components/modules/SubscriberFormInput/SubscriberFormInput.interface';
import routes from '../../../../config/routes';
import type IMedia from '../../../../shared/interfaces/media.interface';
import type IProviderMetadata from '../../../../shared/interfaces/provider-metadata.interface';
import type { IUser } from '../../../user';
import type { IPublication } from '../../publication.interface';
import { updateShowPublicationPosts } from './publicationLanding.service';

interface PublicationLandingProps {
    publication: Pick<
        IPublication,
        'id' | 'title' | 'description' | 'slug' | 'createdAt' | 'thematic' | 'isPaid'
    > & {
        subscriberLists: SubscriberInputPublication['subscriberLists'];
        user: Pick<IUser, 'pseudoName'>;
        logo:
            | (Pick<IMedia, 'url'> & { provider_metadata: Pick<IProviderMetadata, 'public_id'> })
            | null;
    };
    setIsShowPublicationPosts: Dispatch<SetStateAction<boolean>>;
}

export default function PublicationLanding({
    publication,
    setIsShowPublicationPosts,
}: PublicationLandingProps): JSX.Element {
    return (
        <BasicLayout className="bg-transparent">
            <Seo
                title={publication.title}
                description={publication.description}
                image={publication.logo?.url}
                type="publication"
                url={routes.publicationSlug.getUrl(publication.slug)}
                author={publication.user.pseudoName}
                createdAt={publication.createdAt}
                thematic={publication.thematic}
            />
            <Head>
                <link rel="shortcut icon" href={publication.logo?.url} />
            </Head>
            <div className="m-auto p-4 md:p-0 text-center max-w-md">
                <AvatarPartager
                    provider_metadata={publication.logo?.provider_metadata}
                    resizeParameters={fill().width(160).height(160)}
                    name={publication.title}
                    size={20}
                    square
                    className="mx-auto mb-10"
                />
                <SubscriberForm publication={publication} horizontalInput />
                <Button
                    color="link"
                    type="button"
                    onClick={() => {
                        updateShowPublicationPosts(publication.id, true);
                        setIsShowPublicationPosts(true);
                    }}>
                    Laissez moi lire d&apos;abord
                </Button>
            </div>
        </BasicLayout>
    );
}
