import { fill } from '@cloudinary/url-gen/actions/resize';
import IconLock from 'bootstrap-icons/icons/lock-fill.svg';
import IconReply from 'bootstrap-icons/icons/reply.svg';
import React from 'react';

import routes from '../../../../config/routes';
import type { IAction } from '../../../../features/action';
import type { IPost } from '../../../../features/post';
import type { IPublication } from '../../../../features/publication';
import { getRelativePath } from '../../../../features/publication/components/PublicationPosts/PublicationPosts.service';
import type { IUser } from '../../../../features/user';
import { formatDateRelative } from '../../../../lib/helper/date-helper';
import { isPostHasPaywall } from '../../../../lib/helper/post-helper';
import useAction from '../../../../lib/hooks/likeHook';
import type IMedia from '../../../../shared/interfaces/media.interface';
import type IProviderMetadata from '../../../../shared/interfaces/provider-metadata.interface';
import CommentCounter from '../../../basic/CommentCounter';
import LikeCounter from '../../../basic/LikeCounter';
import Button from '../../../elements/Button';
import Heading1 from '../../../elements/Heading/Heading1';
import Link from '../../../elements/Link';
import Paragraph from '../../../elements/Paragraph';
import ImageCloudinary from '../../../ImageCloudinary';

export interface PostTeaserBigProps {
    post: Pick<IPost, 'id' | 'title' | 'subtitle' | 'text' | 'createdAt'> & {
        cover:
            | (Pick<IMedia, 'url'> & {
                  provider_metadata: Pick<IProviderMetadata, 'public_id'>;
              })
            | null;
        totalComments?: number;
        actions?: IAction[];
    };
    publication: Pick<IPublication, 'slug'>;
    user: Pick<IUser, 'id' | 'pseudoName' | 'slug'>;
    onClickShare: (postId: number) => void;
    domain: string;
}

export default function PostTeaserBig({
    post,
    publication,
    user,
    onClickShare,
    domain,
}: PostTeaserBigProps): JSX.Element {
    const [totalLikes, isLiked, onLikeClick] = useAction({
        postId: post.id,
        likesProp: post.actions,
    });

    const relativePath = getRelativePath(domain, publication.slug, post.id);

    return (
        <article className="relative flex flex-col md:flex-row auto-cols-mins mb-4 md:mb-12 lg:mb-16 p-4 lg:hover:bg-gray-50 lg:dark:hover:bg-gray-700 rounded-2xl">
            {post.cover?.provider_metadata && (
                <Link href={relativePath} className="hidden md:block mr-8">
                    <ImageCloudinary
                        provider_metadata={post.cover.provider_metadata}
                        alt={post.title}
                        resizeParameters={fill().width(1540)}
                        className="aspect-video"
                    />
                </Link>
            )}
            <div className="flex flex-col grow p-4 md:p-0 md:pl-4 lg:pl-8">
                <Link type="alternative" href={relativePath}>
                    <Heading1 className="!mt-0">{post.title}</Heading1>
                </Link>
                <Paragraph variant="frontend">{post.subtitle}</Paragraph>
                <div className="flex flex-row text-gray-500 text-sm whitespace-nowrap mt-6 lg:mt-auto">
                    <Link
                        type="alternative"
                        href={routes.getUser.getUrl(user.slug ?? user.id)}
                        className="flex flex-row space-x-2 mr-2">
                        <div className="font-bold text-gray-800 dark:text-white">
                            {user.pseudoName}
                        </div>
                    </Link>
                    {isPostHasPaywall(post.text) && (
                        <div className="mr-2">
                            <span className="md:inline-flex">
                                <IconLock className="w-[15px] h-[15px]" />
                            </span>
                        </div>
                    )}
                    <div className="mr-2">
                        <span className="hidden md:inline-flex">
                            {formatDateRelative(post.createdAt)}
                        </span>
                    </div>
                    <div className="flex flex-row ml-auto">
                        <LikeCounter
                            count={totalLikes}
                            liked={isLiked}
                            onClick={() => onLikeClick()}
                            className="mr-4 mt-1"
                        />
                        <CommentCounter count={post.totalComments || 0} className="font-normal" />
                        <Button color="link" onClick={() => onClickShare(post.id)}>
                            <IconReply className="-scale-x-100" />
                        </Button>
                    </div>
                </div>
            </div>
        </article>
    );
}
