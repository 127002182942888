import classNames from '../../../../lib/classnames';
import type { ILayout } from '../layout.interface';

export interface BasicLayoutProps extends ILayout {}

export default function BasicLayout({ children, className }: BasicLayoutProps): JSX.Element {
    return (
        <div className={classNames('bg-gray-50 dark:bg-gray-900 min-h-screen flex', className)}>
            {children}
        </div>
    );
}
