import { useEffect, useState } from 'react';

import routes from '../../config/routes';
import type { IAction } from '../../features/action';
import type { IOfferType } from '../../features/offerType';
import type { IPost } from '../../features/post';
import type { IPublication } from '../../features/publication';
import PublicationLanding from '../../features/publication/components/PublicationLanding';
import { getItemShowPublicationPosts } from '../../features/publication/components/PublicationLanding/publicationLanding.service';
import PublicationStandardPosts from '../../features/publication/components/PublicationStandardPosts';
import type { SchemaOrgBlogDefinition } from '../../features/publication/schema';
import type { ISubscriberList } from '../../features/subscriberList';
import type { IUser } from '../../features/user';
import type IMedia from '../../shared/interfaces/media.interface';
import type IProviderMetadata from '../../shared/interfaces/provider-metadata.interface';
import PublicationStandardLayout from '../modules/Layout/StandardLayout/PublicationStandardLayout';
import Seo from '../modules/Seo';
import SchemaOrg from '../SchemaOrg';

function showPublicationPosts(publicationId: IPublication['id']): boolean {
    return localStorage.getItem(getItemShowPublicationPosts(publicationId)) === 'true';
}

interface PickedSubscriberList
    extends Pick<
        ISubscriberList,
        'id' | 'enable' | 'sourceCode' | 'polarisId' | 'externalId' | 'price'
    > {
    offerType: Pick<IOfferType, 'name'>;
}

interface PickedPublication
    extends Pick<
        IPublication,
        'id' | 'title' | 'slug' | 'description' | 'createdAt' | 'about' | 'thematic' | 'isPaid'
    > {
    subscriberLists: PickedSubscriberList[];
    user: Pick<IUser, 'id' | 'pseudoName' | 'slug'>;
    logo:
        | (Pick<IMedia, 'url'> & {
              provider_metadata: Pick<IProviderMetadata, 'public_id'>;
          })
        | null;
}

interface PickedPost
    extends Pick<
        IPost,
        'id' | 'title' | 'subtitle' | 'createdAt' | 'seoTitle' | 'seoSubtitle' | 'text'
    > {
    cover:
        | (Pick<IMedia, 'url'> & {
              provider_metadata: Pick<IProviderMetadata, 'public_id'>;
          })
        | null;
    seoCover: Pick<IMedia, 'url'> | null;
    totalComments?: number;
    actions?: IAction[];
}

export interface PublicationSlugProps {
    publication: PickedPublication;
    posts: PickedPost[];
    totalPosts: number;
    schemaOrgDefinition: SchemaOrgBlogDefinition;
    canonicalUrl: string;
    domain: string;
}

export default function PublicationSlug({
    publication,
    posts,
    totalPosts,
    schemaOrgDefinition,
    canonicalUrl,
    domain,
}: PublicationSlugProps): JSX.Element {
    const [isShowPublicationPosts, setIsShowPublicationPosts] = useState<boolean>(false);

    useEffect(() => {
        setIsShowPublicationPosts(showPublicationPosts(publication.id));
    }, [publication.id]);

    if (!isShowPublicationPosts) {
        return (
            <PublicationLanding
                publication={publication}
                setIsShowPublicationPosts={setIsShowPublicationPosts}
            />
        );
    }

    return (
        <PublicationStandardLayout publication={publication} user={publication.user}>
            <SchemaOrg canonical={canonicalUrl} definition={schemaOrgDefinition} />
            <Seo
                title={publication.title}
                description={publication.description}
                image={publication.logo?.url}
                type="publication"
                url={routes.publicationSlug.getUrl(publication.slug)}
                author={publication.user.pseudoName}
                createdAt={publication.createdAt}
                thematic={publication.thematic}
            />
            <PublicationStandardPosts
                publication={publication}
                posts={posts}
                totalPosts={totalPosts}
                domain={domain}
            />
        </PublicationStandardLayout>
    );
}
