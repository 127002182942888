import { useState } from 'react';

import Modal from '../../../../components/basic/Modal';
import Button from '../../../../components/elements/Button';
import Paragraph from '../../../../components/elements/Paragraph';
import PostTeaser from '../../../../components/modules/Posts/PostTeaser';
import PostTeaserBig from '../../../../components/modules/Posts/PostTeaserBig';
import IllustrationNoSubscribers from '../../../../components/svgs/illustrations/no-subscribers.svg';
import type IMedia from '../../../../shared/interfaces/media.interface';
import type IProviderMetadata from '../../../../shared/interfaces/provider-metadata.interface';
import type { IAction } from '../../../action';
import type { IPost } from '../../../post';
import ShareModal from '../../../post/components/ShareModal';
import type { IUser } from '../../../user';
import type { IPublication } from '../../publication.interface';

interface PickedPost
    extends Pick<
        IPost,
        'id' | 'title' | 'subtitle' | 'createdAt' | 'seoTitle' | 'seoSubtitle' | 'text'
    > {
    cover:
        | (Pick<IMedia, 'url'> & {
              provider_metadata: Pick<IProviderMetadata, 'public_id'>;
          })
        | null;
    seoCover: Pick<IMedia, 'url'> | null;
    totalComments?: number;
    actions?: IAction[];
}

interface PublicationPostsProps {
    posts: PickedPost[];
    publication: Pick<IPublication, 'slug'> & {
        user: Pick<IUser, 'id' | 'pseudoName' | 'slug'>;
    };
    canFetchMore: boolean;
    handleOnClick: () => void;
    infiniteScrollLoader?: (node: HTMLDivElement) => void;
    domain: string;
}

export default function PublicationPosts({
    posts,
    publication,
    canFetchMore,
    handleOnClick,
    infiniteScrollLoader,
    domain,
}: PublicationPostsProps): JSX.Element {
    const [modal, setModal] = useState<{
        isOpen: boolean;
        post?: PickedPost;
    }>({
        isOpen: false,
        post: undefined,
    });
    const firstPost = posts.length > 0 ? posts[0] : undefined;
    const nextPosts = posts.length > 1 ? posts.slice(1, posts.length) : [];

    function handleOnClickShareButton(postId: number): void {
        setModal({
            isOpen: true,
            post: posts.find((nextPost) => nextPost.id === postId),
        });
    }

    if (posts.length === 0) {
        return (
            <div className="mb-12">
                <IllustrationNoSubscribers
                    viewBox="0 0 358 330"
                    className="max-w-[250px] h-auto mx-auto mb-4"
                />
                <Paragraph className="text-center m-auto w-1/2 text-2xl">
                    <span className="font-bold">
                        Il n&apos;y a pas d&apos;article...
                        <br />
                        Pour le moment !
                    </span>
                </Paragraph>
            </div>
        );
    }

    return (
        <div>
            {firstPost && (
                <PostTeaserBig
                    post={firstPost}
                    publication={publication}
                    user={publication.user}
                    onClickShare={(postId) => handleOnClickShareButton(postId)}
                    domain={domain}
                />
            )}
            <div className="max-w-[768px] p-4 md:p-0 flex flex-col space-y-8 mx-auto mb-16">
                {nextPosts?.map((post) => (
                    <PostTeaser
                        key={post.id}
                        post={post}
                        publication={publication}
                        user={publication.user}
                        onClickShare={(postId) => handleOnClickShareButton(postId)}
                        domain={domain}
                    />
                ))}
                {canFetchMore && (
                    <div ref={infiniteScrollLoader} className="mx-auto">
                        <Button
                            onClick={handleOnClick}
                            color="alternative"
                            className="mt-8 w-fit mx-auto">
                            Charger plus d&apos;articles
                        </Button>
                    </div>
                )}
                <Modal
                    isOpen={modal.isOpen}
                    closeModal={() => setModal({ isOpen: false, post: undefined })}>
                    {modal.post && <ShareModal post={modal.post} publication={publication} />}
                </Modal>
            </div>
        </div>
    );
}
