import { useCallback, useRef } from 'react';

interface UseInfiniteScrollProps {
    loading?: boolean;
    fetchMore: () => void;
    isActive?: boolean;
}

export default function useInfiniteScroll({
    loading = false,
    fetchMore,
    isActive = true,
}: UseInfiniteScrollProps): (node: HTMLDivElement) => void {
    const observer = useRef<IntersectionObserver | undefined>();
    return useCallback<(node: HTMLDivElement) => void>(
        (node) => {
            if (loading || !isActive) {
                return;
            }
            if (observer.current) {
                observer.current.disconnect();
            }
            observer.current = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    fetchMore();
                    observer.current?.unobserve(node);
                }
            });
            if (node) {
                observer.current.observe(node);
            }
        },
        [loading, isActive, fetchMore],
    );
}
