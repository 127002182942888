import type { IPublication } from '../..';
import ContainerMedium from '../../../../components/basic/Containers/ContainerMedium';
import Tiptap from '../../../../components/Tiptap';
import useEditorInstance from '../../../../components/Tiptap/useEditorInstance';

interface PublicationAboutProps {
    publication: Pick<IPublication, 'about'>;
}

export default function PublicationAbout({ publication }: PublicationAboutProps): JSX.Element {
    const tiptap = useEditorInstance({
        readOnly: true,
        content: publication.about && JSON.parse(publication.about),
    });

    return (
        <ContainerMedium>
            <Tiptap editor={tiptap} />
        </ContainerMedium>
    );
}
