import type { Dispatch, SetStateAction } from 'react';

import classNames from '../../../../lib/classnames';
import TabsChildren from './TabsChildren';

// TODO rework isActive logic, right now we can have many links with isActive at the same time =(
// TODO make tabs receive ReactNodes and render them based on the value of the active tab

export interface TabsProps {
    className?: string;
    links: Array<{
        id: number;
        name: string;
        isActive: boolean;
        isWarning?: boolean;
    }>;
    setActiveTab: Dispatch<SetStateAction<number>>;
}

export default function Tabs({ links, className, setActiveTab }: TabsProps): JSX.Element {
    function onChangeHandler(itemId: number): void {
        setActiveTab(itemId);
    }

    return (
        <>
            <div className={classNames('md:hidden', className)}>
                <select
                    onChange={(e) => onChangeHandler(parseInt(e.currentTarget.value, 10))}
                    className="mb-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {links.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
            </div>
            <ul className="hidden md:flex -mb-px overflow-auto">
                {links.map((item) => (
                    <li key={item.id}>
                        <TabsChildren
                            itemId={item.id}
                            isActive={item.isActive}
                            isWarning={item.isWarning}
                            setActiveTab={setActiveTab}>
                            {item.name}
                        </TabsChildren>
                    </li>
                ))}
            </ul>
        </>
    );
}
