export interface ContainerMediumProps {
    className?: string;
    children: React.ReactNode;
}

export default function ContainerMedium({
    children,
    className,
}: ContainerMediumProps): JSX.Element {
    return (
        <div className={`container-medium max-w-[768px] mx-auto px-4 md:px-0 ${className}`}>
            {children}
        </div>
    );
}
