import { useState } from 'react';

import Tabs from '../../../../components/modules/Navigation/Tabs';
import type { SubscribeBannerProps } from '../../../../components/modules/SubscribeBanner';
import SubscribeBanner from '../../../../components/modules/SubscribeBanner';
import useInfiniteScroll from '../../../../lib/hooks/useInfiniteScroll';
import type { PickedPost } from '../../../../pages/publication/[slug]/index.page';
import { getPostsWithInsights } from '../../../post';
import type { IUser } from '../../../user';
import type { IPublication } from '../../publication.interface';
import PublicationAbout from '../PublicationAbout';
import PublicationPosts from '../PublicationPosts';
import { getPostQuery } from './PublicationStandardPosts.service';

interface PublicationStandardPostsProps {
    publication: SubscribeBannerProps['publication'] &
        Pick<IPublication, 'about'> & {
            user: Pick<IUser, 'id' | 'pseudoName' | 'slug'>;
        };
    totalPosts: number;
    posts: PickedPost[];
    domain: string;
}

export default function PublicationStandardPosts({
    publication,
    totalPosts,
    posts,
    domain,
}: PublicationStandardPostsProps): JSX.Element {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [postsPaginationPage, setPostsPaginationPage] = useState<number>(2);
    const [nextPosts, setNextPosts] = useState<PickedPost[]>(posts);
    const canFetchMore = totalPosts > nextPosts.length;

    async function fetchMorePosts(): Promise<void> {
        try {
            const query = getPostQuery(publication.id, postsPaginationPage);

            const { data: fetchedPosts } = await getPostsWithInsights<PickedPost>(query);
            setNextPosts([...nextPosts, ...fetchedPosts]);
            setPostsPaginationPage(postsPaginationPage + 1);
        } catch (error) {
            console.error(error);
        }
    }

    const loader = useInfiniteScroll({
        isActive: totalPosts > nextPosts.length,
        fetchMore: fetchMorePosts,
    });

    const links = [
        {
            id: 0,
            isActive: activeTab === 0,
            name: 'Articles',
        },
        {
            id: 1,
            isActive: activeTab === 1,
            name: 'A Propos',
        },
    ];

    return (
        <div className="flex flex-col dark:bg-gray-90 0 min-h-screen">
            {publication.about && (
                <div className="relative">
                    <div className="fixed top-[58px] lg:top-[92px] xl:top-[92px] w-full bg-white dark:bg-gray-800 border-b dark:border-gray-700 z-10 flex justify-center">
                        <Tabs
                            links={links}
                            className="w-full px-3 pt-2"
                            setActiveTab={setActiveTab}
                        />
                    </div>
                </div>
            )}
            <div
                className={`container mx-auto ${
                    posts.length > 0 ? 'mt-32 md:mt-42 lg:mt-60' : 'mt-28 md:mt-32 lg:mt-48'
                } mb-12`}>
                {activeTab === 0 && (
                    <PublicationPosts
                        publication={publication}
                        posts={nextPosts}
                        handleOnClick={() => fetchMorePosts()}
                        canFetchMore={canFetchMore}
                        infiniteScrollLoader={loader}
                        domain={domain}
                    />
                )}
                {activeTab === 1 && <PublicationAbout publication={publication} />}
            </div>
            <SubscribeBanner
                publication={publication}
                user={publication.user}
                className="mt-auto"
            />
        </div>
    );
}
