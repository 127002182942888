const pageSize = 10;

export function getPostQuery(publicationId: number, page: number): Record<string, unknown> {
    return {
        fields: ['id', 'title', 'subtitle', 'createdAt', 'text'],
        populate: {
            actions: {
                filters: { type: 'like' },
                fields: ['id', 'type', 'visitorToken'],
            },
            cover: {
                fields: ['url', 'provider_metadata'],
            },
        },
        filters: {
            publication: publicationId,
        },
        sort: 'createdAt:desc',
        pagination: {
            page,
            pageSize,
        },
    };
}
